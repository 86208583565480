import LINKS from './links';

const STORES = {
  PROMOBIT_STORE_ID: 629,
  ALIEXPRESS_STORE_ID: 195,
  TABS: {
    CATEGORIES: {
      tabName: 'Categorias',
      url: LINKS.SITEMAP,
    },
    COUPONS: {
      tabName: 'Cupons',
      url: LINKS.STORES,
    },
    OFFERS: {
      tabName: 'Ofertas',
      url: LINKS.STORES,
    },
  },
  POPULAR_STORES_LIMIT: 6,
};

export default STORES;
